import axios from 'axios';
import React, { createContext, useState, useEffect } from 'react';
import { LOCAL_STORAGE_KEYS } from "../helpers/constants";


export const AppContext = createContext({});
export const AppContextProvider = ({ children }) => {

    const [routerData, setRouterData] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentLanguageCode, setCurrentLanguageCode] = useState("en");
    const [formData, setFormData] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState(null);
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const [notificationLoading, setNotificationLoading] = useState(false);
    const [newNotificationCount, setNewNotificationCount] = useState(null);
    const [notificationList, setNotificationList] = useState(null);


    // useEffect(() => {
    //     // get page definition
    //     axios.get("http://3.64.64.252:7012/api/dashboardpage")
    //     .then((response) => {
    //         setRouterData(response.data)
    //         setLoading(false)
    //     })
    //     .catch((err) => {
    //         setRouterData([])
    //         console.log(err);
    //         setLoading(false)
    //     })
    // }, [])
    const [sidebarShow, setSidebarShow] = useState(true);
    const [sidebarUnfoldable, setSidebarUnfoldable] = useState(false);
    return (
        <AppContext.Provider value={{
            currentLanguageCode, setCurrentLanguageCode,
            routerData, setRouterData,
            loading, setLoading,
            sidebarShow, setSidebarShow,
            sidebarUnfoldable, setSidebarUnfoldable,
            userInfo, setUserInfo,
            formData, setFormData,
            breadcrumb, setBreadcrumb,
            isNotificationVisible, setIsNotificationVisible,
            notificationLoading, setNotificationLoading,
            newNotificationCount, setNewNotificationCount,
            notificationList, setNotificationList
        }}>
            {children}
        </AppContext.Provider>
    )
}