import React from 'react';
import { AppContextProvider } from './contextapi/AppProvider';



import './scss/style.scss';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icons.min.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { LOCAL_STORAGE_KEYS } from './helpers/constants';
import "react-block-ui/style.css";

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "tr"],
    backend: {
      loadPath: '../assets/locales/{{lng}}/translation.json',
    },
    fallbackLng: "en",
    detection: {
      order: ['cookie', 'htmlTag', 'querystring', 'navigator', 'path', 'subdomain'],
      caches: ['cookie']
    },
  })

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


const Projects = React.lazy(() => import('./containers/pages/Projects'));



const PrivateRoute = ({ children }) => {
  const isUserAuth = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_USER_AUTH);

  return children;
}

const App = () => {
  return (
    <React.Suspense fallback={loading}>
      <AppContextProvider>
        <BrowserRouter>
          <Routes>
            {/* <Route exact path="/login" element={<Login />} /> */}
            <Route
              path="/"
              element={<Navigate to="/Projects" replace />}
            />
            <Route path="Projects" element={<PrivateRoute><Projects /></PrivateRoute>} />ß
          </Routes>
        </BrowserRouter>
      </AppContextProvider>
    </React.Suspense>
  );
}

export default App;
