export const LANGUAGES = [
    {
        code: "en",
        name: "English",
        country_code: "gb"
    },
    {
        code: "tr",
        name: "Türkçe",
        country_code: "tr"
    }
]


export const LOCAL_STORAGE_KEYS = {
    IS_USER_AUTH: "IS_USER_AUTH",
    USER_INFO: "USER_INFO",
    IS_NOTIFICATION_VISIBLE: "IS_NOTIFICATION_VISIBLE",
    SELECTED_PURCHASE_ORDER_DATA: "SELECTED_PURCHASE_ORDER_DATA",
    SELECTED_PURCHASE_ORDER_NO: "SELECTED_PURCHASE_ORDER_NO",
}

